/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import "../styles/globals.scss";
import "@severalbrands/form-fusion/styles";
import * as React from "react";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import { Provider as JotaiProvider } from "jotai";
import { Analytics } from "@vercel/analytics/react";
import {
    QueryClient,
    QueryClientProvider,
    Hydrate,
    DehydratedState,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import DefaultLayout from "@layout/default";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { getUserSession } from "src/api/session";
import { setVisitorInfo } from "@lib/sharedUtils";
import { FormProvider } from "@components/shared/form/formReducer/FormReducer";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { DEFAULT_OPTIONS } from "@lib/reactQueryDefaultOptions";
import { FormProvider as FormFusionProvider } from "@severalbrands/form-fusion";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type Props = DehydratedState | any;

export default function MyApp({ Component, pageProps }: Props) {
    const router = useRouter();
    const queryClient = React.useRef(new QueryClient(DEFAULT_OPTIONS));

    // declare the data fetching function
    const fetchData = async () => {
        const data = await getUserSession(
            window.location.href,
            document.referrer,
        );
        if (data && data.data) {
            queryClient.current.setQueryData(["session"], data.data);
            //@ts-ignore
            setVisitorInfo(data.data);
        }
    };

    useEffect(() => {
        fetchData().catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
    }, [router.asPath]);

    return (
        <>
            <QueryClientProvider client={queryClient.current}>
                <Hydrate state={pageProps.dehydratedState}>
                    <FormProvider>
                        <JotaiProvider>
                            <FormFusionProvider>
                                <DefaultLayout
                                    pageType={pageProps.pageType}
                                    pageProps={pageProps}
                                >
                                    <Component
                                        key={router.asPath}
                                        {...pageProps}
                                    />
                                </DefaultLayout>
                            </FormFusionProvider>
                        </JotaiProvider>
                    </FormProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </Hydrate>
            </QueryClientProvider>
            <SpeedInsights />
            <Analytics />
        </>
    );
}
